@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.snackbar {
  z-index: 10000000000; // to overlap fresh chat

  &.bottom {
    bottom: spacing(2);
  }

  @include devices(sm-down) {
    left: spacing(2);
    right: spacing(2);
  }

  @include devices(sm-up) {
    max-width: 420px;
  }
}

.content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 4px;
  background-color: var(--contextual-colors-ui-primary);
  margin: 0;

  &.contentClickable {
    cursor: pointer;
    transition: background 0.25s !important;
    background-color: var(--contextual-colors-text-body);

    &:hover {
      background-color: var(--contextual-colors-ui-primary);
    }
  }
}

.message {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: spacing(1);
  padding: 0;
  font-size: rem(14);
}

.closeButton {
  font-size: rem(14);
  font-weight: var(--font-weight-bold);
  color: var(--contextual-colors-brand-primary);
}
