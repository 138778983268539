@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  display: flex;
  flex-grow: 1;

  @include devices(md-up) {
    gap: 1px;
  }
}

.isChatHistoryEmpty {
  flex-direction: column;
  gap: spacing(1);

  .sectionChat {
    display: flex;
  }

  @include devices(md-up) {
    flex-direction: row;

    .sectionList {
      order: 2;
    }

    .sectionChat {
      order: 1;
    }
  }
}

.section {
  background-color: var(--common-white);
  border-radius: 4px;
}

.sectionList {
  width: 100%;

  @include devices(md-up) {
    width: calc(100% / 3);
    flex-shrink: 0;
  }
}

.sectionChat {
  display: none;
  flex-grow: 1;
  flex-direction: column;

  @include devices(md-up) {
    display: flex;
  }
}

.backLink {
  padding: spacing(1.5);

  @include devices(sm-up) {
    display: none;
  }
}

.segment {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: spacing(1.5);
  gap: spacing(2);

  @include devices(md-up) {
    height: 100%;
  }
}

.title {
  font-size: rem(20);
  font-weight: var(--font-weight-bold);
  margin: 0;

  @include devices(md-up) {
    font-size: rem(24);
  }
}

.subtitle {
  display: inline-block;
  font-size: rem(14);
  color: var(--contextual-colors-ui-primary);
  font-weight: var(--font-weight-regular);
  opacity: 0.5;

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.listWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  @include devices(md-up) {
    max-width: 430px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  list-style: none;
  margin: 0;
  padding: 0;
  gap: spacing(2);

  @include devices(md-up) {
    flex-grow: initial;
  }
}

.textItem {
  padding: spacing(1.5, 2);
  background-color: var(--contextual-colors-bg-main);
  border: 1px solid var(--contextual-colors-neutral);
  border-radius: 0px 4px 4px 4px;
  border-left-width: 3px;
  transition: background 0.1s, border 0.1s;
}

.spinner {
  margin: 0 auto;
}
