@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  color: var(--primary-main);
  background-color: var(--contextual-colors-bg-primary-accent);
  border-radius: 4px 4px 0 0;
  padding: spacing(1.5, 2);
  display: flex;
  align-items: center;

  @include devices(sm-up) {
    padding-left: spacing(7.5);
    padding-right: spacing(7.5);
    flex-direction: row;
    justify-content: space-between;
  }
}

.rootMinimalSpacing {
  @include devices(sm-up) {
    padding-left: spacing(2);
    padding-right: spacing(2);
  }
}

.textWrapper {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @include devices(sm-up) {
    justify-content: left;
  }
}

.text {
  margin-bottom: 0;

  &:not(:first-child) {
    margin-left: 5px;
  }
}

.buttonText {
  @include devices(md-down) {
    font-size: rem(14);
    font-weight: var(--font-weight-medium);
  }
}

.spinner {
  margin-right: spacing(2);

  @include devices(sm-up) {
    margin: spacing(0, 0, 0, 1);
  }
}

.buttonWrapper {
  margin-left: spacing(1.5);
  display: flex;
}

.button {
  min-width: auto;
  width: 205px;

  @include devices(md-down) {
    padding: 0;
    width: 18px;
    height: 18px;
    border-color: var(--primary-dark);
  }
}

.boldText {
  font-weight: var(--font-weight-bold);
}

.addBalanceText {
  display: none;

  @include devices(md-up) {
    display: inline;
  }
}
