@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

$gridGap4: 4;
$gridGap8: 8;
$gridGap12: 12;
$gridGap16: 16;

.group {
  display: flex;
  gap: $gridGap8 + px;
}

.displayFlex {
  display: flex;
}

.flexDirectionRow {
  flex-direction: row;
}

.flexDirectionColumn {
  flex-direction: column;
}

.alignItemsCenter {
  align-items: center;
}

.alignItemsFlexStart {
  align-items: flex-start;
}

.alignSelfStretch {
  align-self: stretch;
}

.justifyContentCenter {
  justify-content: center;
}

.justifyContentEnd {
  justify-content: flex-end;
}

.flexGrow1 {
  flex-grow: 1;
}

.order1 {
  order: 1;
}

.order2 {
  order: 2;
}

.col1 {
  width: calcColumnWidth(1);
}
.col2 {
  width: calcColumnWidth(2);
}
.col3 {
  width: calcColumnWidth(3);
}
.col4 {
  width: calcColumnWidth(4);
}
.col5 {
  width: calcColumnWidth(5);
}
.col6 {
  width: calcColumnWidth(6);
}
.col7 {
  width: calcColumnWidth(7);
}
.col8 {
  width: calcColumnWidth(8);
}
.col9 {
  width: calcColumnWidth(9);
}
.col10 {
  width: calcColumnWidth(10);
}
.col11 {
  width: calcColumnWidth(11);
}
.col12 {
  width: calcColumnWidth(12);
}

.gap4 {
  gap: $gridGap4 + px;

  & .col1 {
    width: calcColumnWidth(1, $gridGap4);
  }
  & .col2 {
    width: calcColumnWidth(2, $gridGap4);
  }
  & .col3 {
    width: calcColumnWidth(3, $gridGap4);
  }
  & .col4 {
    width: calcColumnWidth(4, $gridGap4);
  }
  & .col5 {
    width: calcColumnWidth(5, $gridGap4);
  }
  & .col6 {
    width: calcColumnWidth(6, $gridGap4);
  }
  & .col7 {
    width: calcColumnWidth(7, $gridGap4);
  }
  & .col8 {
    width: calcColumnWidth(8, $gridGap4);
  }
  & .col9 {
    width: calcColumnWidth(9, $gridGap4);
  }
  & .col10 {
    width: calcColumnWidth(10, $gridGap4);
  }
  & .col11 {
    width: calcColumnWidth(11, $gridGap4);
  }
  & .col12 {
    width: calcColumnWidth(12, $gridGap4);
  }
}

.gap12 {
  gap: $gridGap12 + px;

  & .col1 {
    width: calcColumnWidth(1, $gridGap12);
  }
  & .col2 {
    width: calcColumnWidth(2, $gridGap12);
  }
  & .col3 {
    width: calcColumnWidth(3, $gridGap12);
  }
  & .col4 {
    width: calcColumnWidth(4, $gridGap12);
  }
  & .col5 {
    width: calcColumnWidth(5, $gridGap12);
  }
  & .col6 {
    width: calcColumnWidth(6, $gridGap12);
  }
  & .col7 {
    width: calcColumnWidth(7, $gridGap12);
  }
  & .col8 {
    width: calcColumnWidth(8, $gridGap12);
  }
  & .col9 {
    width: calcColumnWidth(9, $gridGap12);
  }
  & .col10 {
    width: calcColumnWidth(10, $gridGap12);
  }
  & .col11 {
    width: calcColumnWidth(11, $gridGap12);
  }
  & .col12 {
    width: calcColumnWidth(12, $gridGap12);
  }
}

.gap16 {
  gap: $gridGap16 + px;

  & .col1 {
    width: calcColumnWidth(1, $gridGap16);
  }
  & .col2 {
    width: calcColumnWidth(2, $gridGap16);
  }
  & .col3 {
    width: calcColumnWidth(3, $gridGap16);
  }
  & .col4 {
    width: calcColumnWidth(4, $gridGap16);
  }
  & .col5 {
    width: calcColumnWidth(5, $gridGap16);
  }
  & .col6 {
    width: calcColumnWidth(6, $gridGap16);
  }
  & .col7 {
    width: calcColumnWidth(7, $gridGap16);
  }
  & .col8 {
    width: calcColumnWidth(8, $gridGap16);
  }
  & .col9 {
    width: calcColumnWidth(9, $gridGap16);
  }
  & .col10 {
    width: calcColumnWidth(10, $gridGap16);
  }
  & .col11 {
    width: calcColumnWidth(11, $gridGap16);
  }
  & .col12 {
    width: calcColumnWidth(12, $gridGap16);
  }
}

@include devices(sm-up) {
  .flexDirectionSmRow {
    flex-direction: row;
  }
  .flexDirectionSmColumn {
    flex-direction: column;
  }

  .alignItemsSmCenter {
    align-items: center;
  }
  .alignItemsSmFlexStart {
    align-items: flex-start;
  }

  .orderSm1 {
    order: 1;
  }

  .orderSm2 {
    order: 2;
  }

  .colSm1 {
    width: calcColumnWidth(1);
  }
  .colSm2 {
    width: calcColumnWidth(2);
  }
  .colSm3 {
    width: calcColumnWidth(3);
  }
  .colSm4 {
    width: calcColumnWidth(4);
  }
  .colSm5 {
    width: calcColumnWidth(5);
  }
  .colSm6 {
    width: calcColumnWidth(6);
  }
  .colSm7 {
    width: calcColumnWidth(7);
  }
  .colSm8 {
    width: calcColumnWidth(8);
  }
  .colSm9 {
    width: calcColumnWidth(9);
  }
  .colSm10 {
    width: calcColumnWidth(10);
  }
  .colSm11 {
    width: calcColumnWidth(11);
  }
  .colSm12 {
    width: calcColumnWidth(12);
  }

  .gap16 {
    gap: $gridGap16 + px;

    & .colSm1 {
      width: calcColumnWidth(1, $gridGap16);
    }
    & .colSm2 {
      width: calcColumnWidth(2, $gridGap16);
    }
    & .colSm3 {
      width: calcColumnWidth(3, $gridGap16);
    }
    & .colSm4 {
      width: calcColumnWidth(4, $gridGap16);
    }
    & .colSm5 {
      width: calcColumnWidth(5, $gridGap16);
    }
    & .colSm6 {
      width: calcColumnWidth(6, $gridGap16);
    }
    & .colSm7 {
      width: calcColumnWidth(7, $gridGap16);
    }
    & .colSm8 {
      width: calcColumnWidth(8, $gridGap16);
    }
    & .colSm9 {
      width: calcColumnWidth(9, $gridGap16);
    }
    & .colSm10 {
      width: calcColumnWidth(10, $gridGap16);
    }
    & .colSm11 {
      width: calcColumnWidth(11, $gridGap16);
    }
    & .colSm12 {
      width: calcColumnWidth(12, $gridGap16);
    }
  }

  .gapSm16 {
    gap: $gridGap16 + px;
  }
}

@include devices(md-up) {
  .flexDirectionMdRow {
    flex-direction: row;
  }
  .flexDirectionMdColumn {
    flex-direction: column;
  }

  .alignItemsMdCenter {
    align-items: center;
  }
  .alignItemsMdFlexStart {
    align-items: flex-start;
  }

  .colMd1 {
    width: calcColumnWidth(1);
  }
  .colMd2 {
    width: calcColumnWidth(2);
  }
  .colMd3 {
    width: calcColumnWidth(3);
  }
  .colMd4 {
    width: calcColumnWidth(4);
  }
  .colMd5 {
    width: calcColumnWidth(5);
  }
  .colMd6 {
    width: calcColumnWidth(6);
  }
  .colMd7 {
    width: calcColumnWidth(7);
  }
  .colMd8 {
    width: calcColumnWidth(8);
  }
  .colMd9 {
    width: calcColumnWidth(9);
  }
  .colMd10 {
    width: calcColumnWidth(10);
  }
  .colMd11 {
    width: calcColumnWidth(11);
  }
  .colMd12 {
    width: calcColumnWidth(12);
  }

  .gapMd8 {
    gap: $gridGap8 + px;

    & .col1 {
      width: calcColumnWidth(1, $gridGap8);
    }
    & .col2 {
      width: calcColumnWidth(2, $gridGap8);
    }
    & .col3 {
      width: calcColumnWidth(3, $gridGap8);
    }
    & .col4 {
      width: calcColumnWidth(4, $gridGap8);
    }
    & .col5 {
      width: calcColumnWidth(5, $gridGap8);
    }
    & .col6 {
      width: calcColumnWidth(6, $gridGap8);
    }
    & .col7 {
      width: calcColumnWidth(7, $gridGap8);
    }
    & .col8 {
      width: calcColumnWidth(8, $gridGap8);
    }
    & .col9 {
      width: calcColumnWidth(9, $gridGap8);
    }
    & .col10 {
      width: calcColumnWidth(10, $gridGap8);
    }
    & .col11 {
      width: calcColumnWidth(11, $gridGap8);
    }
    & .col12 {
      width: calcColumnWidth(12, $gridGap8);
    }

    & .colMd1 {
      width: calcColumnWidth(1, $gridGap8);
    }
    & .colMd2 {
      width: calcColumnWidth(2, $gridGap8);
    }
    & .colMd3 {
      width: calcColumnWidth(3, $gridGap8);
    }
    & .colMd4 {
      width: calcColumnWidth(4, $gridGap8);
    }
    & .colMd5 {
      width: calcColumnWidth(5, $gridGap8);
    }
    & .colMd6 {
      width: calcColumnWidth(6, $gridGap8);
    }
    & .colMd7 {
      width: calcColumnWidth(7, $gridGap8);
    }
    & .colMd8 {
      width: calcColumnWidth(8, $gridGap8);
    }
    & .colMd9 {
      width: calcColumnWidth(9, $gridGap8);
    }
    & .colMd10 {
      width: calcColumnWidth(10, $gridGap8);
    }
    & .colMd11 {
      width: calcColumnWidth(11, $gridGap8);
    }
    & .colMd12 {
      width: calcColumnWidth(12, $gridGap8);
    }
  }

  .gapMd12 {
    gap: $gridGap12 + px;

    & .col1 {
      width: calcColumnWidth(1, $gridGap12);
    }
    & .col2 {
      width: calcColumnWidth(2, $gridGap12);
    }
    & .col3 {
      width: calcColumnWidth(3, $gridGap12);
    }
    & .col4 {
      width: calcColumnWidth(4, $gridGap12);
    }
    & .col5 {
      width: calcColumnWidth(5, $gridGap12);
    }
    & .col6 {
      width: calcColumnWidth(6, $gridGap12);
    }
    & .col7 {
      width: calcColumnWidth(7, $gridGap12);
    }
    & .col8 {
      width: calcColumnWidth(8, $gridGap12);
    }
    & .col9 {
      width: calcColumnWidth(9, $gridGap12);
    }
    & .col10 {
      width: calcColumnWidth(10, $gridGap12);
    }
    & .col11 {
      width: calcColumnWidth(11, $gridGap12);
    }
    & .col12 {
      width: calcColumnWidth(12, $gridGap12);
    }

    & .colMd1 {
      width: calcColumnWidth(1, $gridGap12);
    }
    & .colMd2 {
      width: calcColumnWidth(2, $gridGap12);
    }
    & .colMd3 {
      width: calcColumnWidth(3, $gridGap12);
    }
    & .colMd4 {
      width: calcColumnWidth(4, $gridGap12);
    }
    & .colMd5 {
      width: calcColumnWidth(5, $gridGap12);
    }
    & .colMd6 {
      width: calcColumnWidth(6, $gridGap12);
    }
    & .colMd7 {
      width: calcColumnWidth(7, $gridGap12);
    }
    & .colMd8 {
      width: calcColumnWidth(8, $gridGap12);
    }
    & .colMd9 {
      width: calcColumnWidth(9, $gridGap12);
    }
    & .colMd10 {
      width: calcColumnWidth(10, $gridGap12);
    }
    & .colMd11 {
      width: calcColumnWidth(11, $gridGap12);
    }
    & .colMd12 {
      width: calcColumnWidth(12, $gridGap12);
    }
  }

  .gapMd16 {
    gap: $gridGap16 + px;

    & .col1 {
      width: calcColumnWidth(1, $gridGap16);
    }
    & .col2 {
      width: calcColumnWidth(2, $gridGap16);
    }
    & .col3 {
      width: calcColumnWidth(3, $gridGap16);
    }
    & .col4 {
      width: calcColumnWidth(4, $gridGap16);
    }
    & .col5 {
      width: calcColumnWidth(5, $gridGap16);
    }
    & .col6 {
      width: calcColumnWidth(6, $gridGap16);
    }
    & .col7 {
      width: calcColumnWidth(7, $gridGap16);
    }
    & .col8 {
      width: calcColumnWidth(8, $gridGap16);
    }
    & .col9 {
      width: calcColumnWidth(9, $gridGap16);
    }
    & .col10 {
      width: calcColumnWidth(10, $gridGap16);
    }
    & .col11 {
      width: calcColumnWidth(11, $gridGap16);
    }
    & .col12 {
      width: calcColumnWidth(12, $gridGap16);
    }

    & .colMd1 {
      width: calcColumnWidth(1, $gridGap16);
    }
    & .colMd2 {
      width: calcColumnWidth(2, $gridGap16);
    }
    & .colMd3 {
      width: calcColumnWidth(3, $gridGap16);
    }
    & .colMd4 {
      width: calcColumnWidth(4, $gridGap16);
    }
    & .colMd5 {
      width: calcColumnWidth(5, $gridGap16);
    }
    & .colMd6 {
      width: calcColumnWidth(6, $gridGap16);
    }
    & .colMd7 {
      width: calcColumnWidth(7, $gridGap16);
    }
    & .colMd8 {
      width: calcColumnWidth(8, $gridGap16);
    }
    & .colMd9 {
      width: calcColumnWidth(9, $gridGap16);
    }
    & .colMd10 {
      width: calcColumnWidth(10, $gridGap16);
    }
    & .colMd11 {
      width: calcColumnWidth(11, $gridGap16);
    }
    & .colMd12 {
      width: calcColumnWidth(12, $gridGap16);
    }
  }
}
