@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.pageContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.authContainer {
  background-color: var(--common-white);
  padding: spacing(0, 1);
  margin: 0 auto;

  @include devices(sm-up) {
    padding: spacing(0, 2);
  }
}
