@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.paperBodytext {
  font-size: rem(14);
  line-height: 1.2;
  margin-bottom: spacing(1.5);

  @include devices(sm-up) {
    margin-bottom: spacing(3);
    font-size: rem(16);
  }
}

.noGutter {
  margin-bottom: 0;
}
