@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.root {
  background-color: var(--common-white);
  color: var(--text-primary);
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--contextual-colors-ui-secondary);
  transition: all 0.1s;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: spacing(1);
  height: 63px;
  min-width: 98px;

  &:not(:last-child) {
    @include devices(sm-up) {
      min-width: max-content;
    }
  }

  :global(#paypal-small) {
    transition: opacity 0.2s;
    opacity: 0.3;
  }

  @include devices(sm-up) {
    padding: spacing(1, 2);
    flex-direction: row;
    height: 50px;

    &:hover {
      color: var(--contextual-colors-brand-primary-hover);

      :global(#paypal-small) {
        opacity: 0.4;
      }
    }
  }
}

.active {
  background-color: var(--action-selected);
  border-color: var(--primary-main);
  color: var(--primary-main);

  :global(#paypal-small) {
    opacity: 1;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--primary-main);

    :global(#paypal-small) {
      opacity: 1;
    }
  }
}

.rootHorizontal {
  max-width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  height: 50px;
}

.iconBase {
  height: 25px;
  width: 25px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3px;

  @include devices(sm-up) {
    margin-right: spacing(1);
  }
}

.iconBaseHorizontal {
  margin-bottom: 0;
  margin-right: spacing(1);

  @include devices(sm-up) {
    height: 25px;
  }
}

.label {
  font-size: 14px;
  color: inherit;
  text-transform: capitalize;
  font-weight: var(--font-weight-medium);

  @include devices(sm-up) {
    font-size: 16px;
    font-weight: var(--font-weight-bold);
  }
}
