@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.avatarWrapper {
  width: 53px;

  @include devices(md-up) {
    margin-right: 0;
    width: 85px;
  }
}

.expertName {
  font-weight: var(--font-weight-medium);
  font-size: rem(14);
}

.ratingRoot {
  display: flex;
  align-items: center;
  transform: translateY(-1px);
  gap: 4px;
}

.line {
  display: none;
  width: 100%;
  margin: 0;
  background-color: var(--contextual-colors-neutral);

  @include devices(md-up) {
    display: block;
  }
}
