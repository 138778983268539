.left {
  transform: rotate(180deg);
}

.top {
  transform: rotate(-90deg);
}

.right {
  transform: rotate(0deg);
}

.bottom {
  transform: rotate(90deg);
}
