.priceChunk {
  line-height: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-decoration: none;
  margin-left: 4px;

  &:first-child {
    margin-left: 0;
  }

  &.striken {
    font-weight: var(--font-weight-regular);
    font-size: 0.9em;
    text-decoration: line-through;
  }
}

.noGutter {
  margin-left: 0;
}
