@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.appBar {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
}

.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;

  @include devices(md-up) {
    justify-content: initial;
  }

  @include devices(lg-up) {
    padding: 0;
  }
}
