@use "@styles/partials/functions" as *;

.pageContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.content {
  padding: spacing(2);
}
