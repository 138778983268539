@use "@styles/partials/functions" as *;

.logo {
  color: var(--secondary-main);
  margin-top: spacing(4);
  margin-bottom: spacing(2.5);
}

.mainTitle {
  color: var(--text-secondary);
  font-size: rem(20);
  margin-bottom: spacing(2);
  font-weight: var(--font-weight-bold);
  text-align: center;
}

.mainText {
  color: var(--text-secondary);
  font-size: rem(16);
  margin-bottom: spacing(4);
  font-weight: 500;
  max-width: rem(350);
  text-align: center;
}

.button {
  width: rem(220);
  height: rem(40);
  margin-bottom: spacing(1);
  margin-right: auto;
  margin-left: auto;
  display: block;
}
