@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.container {
  background-color: var(--common-white);
  padding: spacing(2);
  white-space: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.link {
  color: var(--primary-main);
  font-size: rem(12);
  font-weight: var(--font-weight-medium);
  display: inline-flex;
  align-items: center;
  padding: spacing(0, 2.5);
  height: 30px;
  border: 1px solid;
  border-radius: 5px;

  & + * {
    margin-left: spacing(1);
  }
}
