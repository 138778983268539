@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.container {
  padding: spacing(2);

  @include devices(md-down) {
    padding: spacing(2);
    background-color: var(--background-default);
  }
}
