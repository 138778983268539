@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.infoMessage {
  @include devices(md-up) {
    padding: spacing(2, 7.5);
  }

  p {
    font-size: rem(12);
    font-weight: var(--font-weight-regular);
  }
}

.header {
  display: flex;
  justify-content: flex-end;
  padding: spacing(0, 0.5);

  @include devices(md-up) {
    padding: 0;
  }
}
