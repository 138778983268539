@use "@styles/partials/functions" as *;

.linkButton {
  padding: 0;
  border: none !important;
  background-color: transparent !important;
}

.linkButtonLabel {
  font-size: rem(14);
  font-weight: 500;
}
