@use "@styles/partials/functions" as *;

.root {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--contextual-colors-bg-main);
  padding: spacing(0.75, 0);
  border-radius: 8px;
  margin-bottom: spacing(2);
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.avatarWrapper {
  margin-left: spacing(1.5);
  margin-right: spacing(1.75);
  width: 50px;
  height: 56px;
}

.image {
  font-size: rem(12);
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.info {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
}

.expertName {
  font-weight: var(--font-weight-medium);
  font-size: rem(14);
  padding-top: spacing(0.75);
}

.freeOfferWrapper {
  display: flex;
  align-items: center;
  padding-top: spacing(0.75);
  color: var(--secondary-main);
}

.freeOffer {
  display: inline-block;
  margin-left: 7px;
  font-size: rem(14);
  line-height: 22px;
}
