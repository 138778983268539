@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.iconButton {
  color: var(--grey-300);
  transition: color 0.2s;

  &:hover {
    color: var(--text-secondary);
  }
}

.tooltip {
  width: 179px;
  background-color: var(--common-white);
  position: relative;
  color: var(--text-primary);
  padding: spacing(1.5);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  left: 10px;
  top: 18px;

  @include devices(sm-up) {
    top: 8px;
  }
}

.tooltipPlacementBottom {
  top: auto;
  left: auto;
  margin: 0;
}

.tooltipWithIcon {
  width: 142px;
  padding: 11px 14px 22px 18px;
}

.tooltipArrow {
  position: absolute;
  box-shadow: 4px 4px 3px -2px rgba(0, 0, 0, 0.2);
  transform: translateY(-18px) rotate(45deg);
  right: 14px;
  top: 105%;
  border-radius: 6px;
  background-color: var(--common-white);
  width: 20px;
  height: 20px;
}

.tooltipText {
  line-height: 1.2;
  font-size: rem(11);
  clear: both;
}
