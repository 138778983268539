@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.icon {
  transition: opacity 0.15s;
}

.hint {
  font-size: rem(14);
  font-weight: var(--font-weight-medium);
  padding-left: spacing(1.5);
  flex: 1;
  transition: color 0.1s;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;

  @include devices(md-up) {
    font-size: rem(16);
    padding: spacing(0, 1);
  }
}
