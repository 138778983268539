@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.button {
  width: 100%;
}

.withoutLabelButton {
  width: 64px;
  height: 36px;

  span {
    margin: 0;
  }
}

.callbackButton {
  cursor: pointer;
}

.notAvailableButton {
  &:disabled {
    background-color: var(--grey-300);
  }
}

.productTypeText {
  font-weight: var(--font-weight-bold);
  font-size: rem(14);
  white-space: nowrap;

  @include devices(md-up) {
    font-size: inherit;
  }
}

.icon {
  display: none;

  @include devices(s-up) {
    display: inherit;
  }
}

.withoutLabelButtonIcon {
  display: inherit;
}
