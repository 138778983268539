.root {
  height: 100%;
}

.textElement {
  margin-bottom: 4px;
}

.productButtonWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.noPaddings.noPaddings {
  padding: 0;
}
