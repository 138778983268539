@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  border-radius: 8px;
  color: var(--text-primary);
  padding: spacing(2, 1.5);
  font-size: rem(14);

  @include devices(md-up) {
    padding: spacing(4, 0, 0);
    font-size: rem(16);
  }
}

.paperBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.spinnerText {
  margin-top: spacing(2);
  text-align: center;
}
