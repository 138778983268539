@import "@styles/partials/breakpoints";
@import "@styles/partials/functions";

.buttonBase {
  text-transform: none;
  border-radius: 80px;
  box-shadow: none;
  &:hover {
    box-shadow: none;
  }
  &:focus {
    outline: none;
  }
}

.colorSecondary {
  color: var(--primary-contrast-text);
}

.outlinedPrimary {
  background-color: transparent;
}

.buttonSecondary {
  background-color: var(--common-white);
  border: 1px solid var(--primary-main);
  color: var(--primary-main);
  font-weight: 500;
  &:hover {
    background-color: var(--contextual-colors-bg-secondary-accent);
    border-color: var(--primary-dark);
    color: var(--primary-dark);
    &:disabled {
      background-color: var(--common-white);
      border-color: var(--primary-main);
      color: var(--primary-main);
    }
  }
}

.buttonRound {
  width: 40px;
  height: 40px;
  padding: spacing(1.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: none;
  box-shadow: 0 0 4px 0 rgba(135, 135, 135, 0.2);
  &:hover {
    border: none;
  }
}

.labelCentered {
  justify-content: center;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  // add left
}

.label {
  font-size: 1rem;
  color: inherit;
  font-weight: inherit;
}

.iconLabel {
  width: initial;
}

.buttonLabel {
  gap: spacing(1);
}

.buttonLabelPrimaryContained {
  color: var(--common-white);
}

.buttonLabelPrimaryOutlined {
  color: var(--primary-main);
}
