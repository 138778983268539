@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.modal {
  z-index: 10000000000;
  align-items: center;
  display: flex;
  justify-content: center;
  -webkit-overflow-scrolling: touch;
}

.wrapper {
  background-color: var(--common-white);
  height: 100%;
  outline: none;
  overflow-y: auto; // need it for iOS v.12
  position: relative;
  width: 100%;

  @include devices(sm-up) {
    height: 680px;
    max-height: 90vh;
    max-width: 500px;
  }
}

.container {
  height: 100%;
  width: 100%;

  iframe {
    display: block;
  }
}

.closeButton {
  color: var(--text-primary);
  right: 0;
  top: 0;
  position: absolute;
  width: 16px;
  height: 16px;
  margin: spacing(2);
  cursor: pointer;
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
}
