@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.packageSection {
  position: relative;
}

.editableItem {
  display: flex;
  justify-content: space-between;
  cursor: default;
  position: relative;
}

.editButtonBase {
  position: absolute;
  right: 0;
  padding: spacing(1.5, 2);
}

.editButtonEdit {
  top: 50%;
  transform: translateY(-50%);
}

.editButtonClose {
  z-index: 1;
  bottom: 100%;
  padding-right: 0;
}

.productPackage {
  padding-right: 60px;
}
