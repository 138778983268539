@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.paperSpacings {
  width: 100%;

  @include devices(sm-up) {
    padding-left: spacing(7.5);
    padding-right: spacing(7.5);
    padding-bottom: spacing(4);
  }
}

.expansionPanel {
  background-color: var(--contextual-colors-bg-main);
  box-shadow: none;
  border-radius: 4px;
  margin-bottom: spacing(0.5);

  &:before {
    height: 0;
  }
}

.heading {
  font-size: rem(14);
  font-weight: var(--font-weight-regular);

  @include devices(sm-up) {
    font-size: rem(16);
    font-weight: var(--font-weight-medium);
  }
}

.subHeading {
  font-size: rem(12);
  font-weight: var(--font-weight-regular);
  color: var(--text-secondary);
}

.details {
  white-space: pre-wrap;
  background-color: var(--common-white);
}
