@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.input {
  width: 100%;

  @include devices(md-up) {
    width: 262px;
  }
}

.inputField {
  height: 50px;
}

.skeleton {
  width: 50%;
  margin: 0;
  height: 23px;
}

.dateFieldWrapper {
  margin-top: spacing(0.25);
  margin-bottom: spacing(0.25);

  @include devices(md-up) {
    margin-top: spacing(0.5);
    margin-bottom: spacing(0.5);
  }
}
