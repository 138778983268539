@use "@styles/partials/functions" as *;

.root {
  outline: none;
}

.radio {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;

  &:checked,
  &:checked:disabled {
    & + .method {
      background-color: var(--action-selected);
      border-color: var(--primary-main);

      & .hint {
        color: var(--text-primary);
      }

      & .providerIcon {
        opacity: 1;
      }
    }
  }

  &:disabled,
  &:checked:disabled {
    & + .method,
    & + .method:hover {
      cursor: default;
    }
  }

  &:disabled {
    & + .method,
    & + .method:hover {
      border-color: var(--grey-300);

      & .providerIcon {
        opacity: 0.4;
      }

      & .hint {
        color: var(--grey-300);
      }
    }
  }
}

.method {
  display: flex;
  width: 100%;
  height: 50px;
  border: 1px solid var(--grey-300);
  border-radius: 4px;
  align-items: center;
  padding: spacing(1.5);
  transition: border-color 0.1s;
  background-color: var(--contextual-colors-negative);

  &:focus,
  &:hover {
    cursor: pointer;
    border-color: var(--primary-main);

    & .providerIcon {
      opacity: 0.75;
    }

    & .hint {
      color: var(--text-primary);
    }
  }
}

.providerIcon {
  opacity: 0.4;
}

.hint {
  color: var(--grey-300);
}

.actions {
  margin-left: auto;
}

.activateLabel {
  color: var(--primary-main);
  font-size: rem(16);
  font-weight: var(--font-weight-medium);
}
