@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  position: relative;
  color: var(--primary-main);
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    height: 1px;
    width: 100%;
    top: 100%;
    border-bottom: 1px dashed currentColor;
    transition: border-bottom-color 0.2s;
  }

  &:hover:before {
    border-bottom-color: transparent;
  }
}

.pseudoLink {
  font-size: rem(12);

  @include devices(sm-up) {
    font-size: rem(14);
  }
}
