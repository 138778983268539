@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  padding-top: spacing(1.5);
  padding-bottom: spacing(1.5);
  background-color: var(--contextual-colors-bg-secondary-accent);
  display: flex;
  justify-content: center;
  align-items: flex-start;

  @include devices(md-up) {
    align-items: center;
  }
}

.info {
  font-size: rem(12);
  letter-spacing: 0.26;
  color: var(--common-black);
  font-weight: var(--font-weight-medium);

  @include devices(md-up) {
    font-size: rem(14);
    letter-spacing: 0.31;
  }
}

.icon {
  margin-left: spacing(2);
  margin-top: spacing(0.5);

  @include devices(md-up) {
    margin-top: 0;
    height: 22px;
  }
}
