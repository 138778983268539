@use "@styles/partials/functions" as *;

.root {
  font-size: rem(14);
  font-weight: var(--font-weight-medium);
  line-height: normal;
  margin: 0;
  display: flex;
  width: 100%;
}

.iconWrapper {
  width: 18px;
  height: 18px;
  margin-right: spacing(1);
  flex-grow: 0;
  flex-shrink: 0;
}

.successText {
  color: var(--contextual-colors-icons-success);
}
