@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.paperBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: spacing(2, 1.5);

  @include devices(md-up) {
    padding: spacing(4, 4, 6);
  }
}

.title {
  color: var(--error-main);
  font-size: rem(16.8);
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(2);

  @include devices(md-up) {
    font-size: rem(19.2);
    max-width: 100%;
  }
}

.icon {
  width: 51px;
  height: 51px;
  margin-bottom: spacing(2);

  @include devices(md-up) {
    width: 60px;
    height: 60px;
  }
}

.button {
  margin-bottom: spacing(1);
  width: 100%;
  max-width: 352px;
  font-size: rem(16);

  &:last-child {
    margin-bottom: 0;
  }
}

.errorText {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(2);
  font-size: rem(16.8);

  @include devices(md-up) {
    font-size: rem(19.2);
  }
}
