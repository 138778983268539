@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  padding: spacing(1.5);
  background-color: var(--contextual-colors-bg-secondary-accent);
  display: flex;

  @include devices(md-up) {
    align-items: center;
    padding: spacing(2);
  }
}

.icon {
  display: flex;
  height: 22px;
  margin-right: 10px;

  @include devices(md-up) {
    margin-top: -4px;
  }
}

.text {
  font-weight: var(--font-weight-medium);
  font-size: rem(14);

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.warning {
  background-color: var(--contextual-colors-bg-obstacle);
}

.info {
  background-color: var(--contextual-colors-bg-secondary-accent);
}
