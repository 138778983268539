@use "@styles/partials/functions" as *;

.logoutButton {
  background-color: transparent;
  padding: spacing(0.75, 0);
  color: inherit;

  &:hover {
    background-color: transparent;
    color: var(--secondary-main);
  }

  &:active {
    box-shadow: none;
  }
}
