@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.title {
  padding: spacing(2, 1.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleWithIcon {
  padding: spacing(2, 1.5, 0);
}

.flex {
  display: flex;
}

.text {
  font-size: rem(20);
  font-weight: var(--font-weight-bold);
  line-height: 1.3;

  @include devices(sm-up) {
    font-size: rem(24);
  }
}
