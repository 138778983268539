@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.paperBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: spacing(2, 1.5);

  @include devices(md-up) {
    padding: spacing(4, 4, 6);
  }
}

.icon {
  width: 51px;
  height: 51px;
  margin-bottom: spacing(2);

  @include devices(md-up) {
    width: 60px;
    height: 60px;
  }
}

.button {
  margin-bottom: spacing(1);
  width: 100%;
  max-width: 352px;
  font-size: rem(16);
  font-weight: var(--font-weight-bold);

  &:last-child {
    margin-bottom: 0;
  }
}

.title {
  font-size: rem(16.8);
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(2);

  @include devices(md-up) {
    font-size: rem(19.2);
    letter-spacing: 0.21;
    max-width: 100%;
  }
}

.body {
  font-size: rem(14);
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(2);
  text-align: center;

  @include devices(md-up) {
    font-size: rem(16);
    letter-spacing: 0.18;
  }
}
