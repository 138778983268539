@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.icon {
  background-size: contain;
  background-repeat: no-repeat;
  width: 34px;
  height: 24px;
}

.sepa {
  background-image: url("../icons/sepa.svg");
}

.visa {
  background-image: url("../icons/visa.svg");
}

.mc {
  background-image: url("../icons/mc.svg");
}

.amex {
  background-image: url("../icons/amex.svg");
}

.paypal {
  background-image: url("../icons/paypal.svg");
}

.guthaben {
  width: 24px;
}
