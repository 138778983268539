@use "@styles/partials/functions" as *;

.listRoot {
  padding: 0;
  list-style: none;
  margin: 0;
}

.helpText {
  margin-top: spacing(1);
}
