@use "@styles/partials/functions" as *;

.root {
  background-color: var(--contextual-colors-ui-primary);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: spacing(1, 3);
}

.hint {
  color: var(--common-white);
}

.button {
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
}
