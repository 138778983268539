@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.container {
  & > div {
    background-color: var(--common-white);
  }
}

.wrapper {
  padding: spacing(1, 2, 2.5);
}

.titleLink {
  font-weight: var(--font-weight-medium);
  font-size: rem(14);
  line-height: 1.3;
  text-decoration: underline;

  @include devices(sm-up) {
    font-size: rem(16);
  }
}

.markedBackground {
  background-color: var(--contextual-colors-bg-secondary-accent);
}

.description {
  font-size: rem(13);
  padding: spacing(0, 2, 2.5);

  @include devices(sm-up) {
    font-size: rem(16);
    font-weight: var(--font-weight-medium);
  }
}

.scrollNavigation {
  @include devices(sm-up) {
    display: none;
  }
}
