@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.paperSpacings {
  overflow-x: hidden;
}

.paperBody {
  padding: spacing(0);

  @include devices(md-up) {
    padding: spacing(0);
  }
}
