@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  width: 100%;
  border-radius: 4px;
  background-color: var(--common-white);
}

.stickyHeader {
  position: sticky;
  top: 64px;
  z-index: 2;
  background-color: var(--common-white);
  box-shadow: 0px 2px 2px rgba(147, 147, 147, 0.25);
}

.withLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: spacing(5);
  color: var(--contextual-colors-ui-secondary);
  flex: 1;
}

.body {
  padding: spacing(1.5);
  width: 100%;

  @include devices(md-up) {
    padding: spacing(2, 7.5);
    margin: 0;
  }
}

.noTopPadding {
  padding-top: 0;
}
