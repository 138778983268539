@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.fieldset {
  margin: spacing(0, 0, 2);
  border: none;
  padding: 0;
  width: 100%;
  min-width: 0;

  @include devices(sm-up) {
    background-color: var(--contextual-colors-bg-main);
    border: 1px solid var(--contextual-colors-neutral);
    border-radius: 8px;
    padding: spacing(2, 3);
  }
}

.disabled {
  opacity: 0.4;
}
