@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: spacing(4, 1, 0);

  @include devices(md-up) {
    padding: spacing(4, 2, 2);
  }
}

.authTitle {
  margin: spacing(1, 0, 0);
  font-size: rem(16);
  @include devices(md-up) {
    margin-bottom: spacing(3);
    font-size: rem(19);
  }
}

.authTitleNoMargin {
  @include devices(md-up) {
    margin-bottom: 0;
  }
}
