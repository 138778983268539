@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.postPaymentContainer {
  max-width: 700px;
  padding: spacing(1.5);

  @include devices(md-up) {
    padding: spacing(2, 7.5);
  }
}
