@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.package {
  width: 100%;
  border: 1px solid var(--grey-400);
  border-radius: 4px;
  min-height: 50px;
  align-items: center;
  padding: spacing(1.5);
  transition: border-color 0.1s;
  position: relative;
  margin: 0;
  background-color: var(--contextual-colors-negative);

  @include devices(sm-up) {
    height: 70px;
  }
}

.packageActive {
  background-color: var(--action-selected);
  border-color: var(--primary-main);
}

.label {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  font-size: rem(14);

  &.disabled {
    color: var(--text-primary);
  }

  @include devices(sm-up) {
    flex-direction: column;
    font-size: rem(16);
  }
}

.labelActive {
  color: var(--primary-main);
  font-weight: var(--font-weight-medium);
}

.radioButton {
  padding: 0;
  margin-right: spacing(1);

  & svg {
    width: rem(14);
    height: rem(14);
  }

  @include devices(md-up) {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
  }
}

.radioButtonHidden {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
}

.leftCol {
  white-space: nowrap;
  margin-right: spacing(1);

  @include devices(md-up) {
    margin-right: 0;
  }
}

.chunk {
  font-size: inherit;
  font-weight: inherit;

  &:not(:last-child) {
    margin-right: spacing(0.5);
  }
}

.chunkBold {
  font-weight: var(--font-weight-bold);
}

.priceWrapper {
  flex: 1;
  display: inline-flex;
  align-items: baseline;
}

.priceWrapperLeft {
  text-align: left;
}

.priceWrapperRight {
  text-align: right;
}

.packagePromo {
  position: absolute;
  top: -7px;
  left: -2px;
  font-weight: var(--font-weight-medium);
  background-color: var(--secondary-main);
  color: var(--common-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 16px;
  line-height: 17px;
  text-align: center;
  padding: 0 spacing(5);
  border-radius: 4px 4px 4px 0;
  font-size: rem(10);

  @include devices(s-down) {
    font-size: rem(11);
  }

  @include devices(sm-up) {
    font-size: rem(12);
  }
}

.packagePromoInverted {
  background-color: var(--common-white);
  color: var(--secondary-main);
  border: 1px solid;
}
