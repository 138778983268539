@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.anchorBottom {
  max-height: 80vh;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding-top: spacing(4);

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: spacing(1.5);
    right: 50%;
    margin-right: -26px;
    width: 52px;
    height: 4px;
    background-color: #afa8a9;
    border-radius: 30px;
  }
}

.anchorLeft {
  min-width: 288px;
  max-width: 400px;
}
