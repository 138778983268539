@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.wrapper {
  padding: spacing(2);
  width: 100%;
  height: 100%;
  background-color: var(--common-white);
  border-radius: 4px;
}

.avatarWrapper {
  float: left;
  width: 100%;
  height: 63px;

  @include devices(md-up) {
    float: none;
    height: 70%;
  }
}

.avatarSkeleton {
  min-height: 100%;
}

.detailsCol {
  flex: 1;
}
