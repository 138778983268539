@use "@styles/partials/functions" as *;

.root {
  padding: 0;
  list-style: none;
  display: flex;
  margin: 0;
}

.item {
  &:not(:last-child) {
    margin-right: spacing(1);
  }
}
