@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.button {
  height: 30px;
  width: 30px;
  padding: 0;
  position: absolute;
  top: calc(50% - 15px);
  z-index: 10;
  background-color: var(--common-white);
  border: 1px solid;
  border-radius: 50%;
  color: var(--primary-main);
  line-height: 0;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--primary-dark);
    color: var(--common-white);
  }

  &.prev {
    left: spacing(2);
  }

  &.next {
    transform: rotate(180deg);
    right: spacing(2);
  }
}
