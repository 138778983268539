@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.label {
  white-space: nowrap;
}

.field {
  :global(.MuiOutlinedInput-notchedOutline) {
    border-width: 1px !important;
  }

  :global(.Mui-disabled) {
    background-color: var(--contextual-colors-neutral);
  }
}

.fieldError {
  :global(.Mui-disabled .MuiOutlinedInput-notchedOutline) {
    border-color: var(--error-main);
  }
}

.input {
  display: flex;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;
  height: 50px;
  font-size: rem(14);
  font-weight: var(--font-weight-medium);

  &[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.gutter {
  margin-bottom: spacing(2);
}
