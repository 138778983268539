@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  display: flex;
  min-height: 42px;
  align-items: center;
  position: relative;
  background-color: var(--common-white);
  width: 100%;
}

.text {
  width: 100%;
  line-height: 1.2;
  font-size: rem(14);
  font-weight: var(--font-weight-bold);

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.textBig {
  font-size: rem(16);

  @include devices(md-up) {
    font-size: rem(20);
  }
}

.textWithDivider {
  border-bottom: 1px solid var(--contextual-colors-neutral);
}

.link {
  display: flex;
  align-items: center;
  flex: 1;
}

.linkDesktop {
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--contextual-colors-bg-secondary-accent);
  }
}

.arrow {
  line-height: 0;
  transform: rotate(180deg);
  margin-right: spacing(1);
}

.extraLeftOffset {
  @include devices(md-down) {
    padding-left: spacing(3.5);
  }
}
