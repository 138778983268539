@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.ads {
  position: relative;
  background-color: var(--contextual-colors-bg-primary-accent);
  padding-top: spacing(2);
  padding-bottom: spacing(3.5);

  @include devices(md-up) {
    border: 2px solid var(--common-white);
    border-radius: 8px;
  }
}

.list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 5%;
  width: auto;
  padding: 0;
  margin: 0;

  @include devices(sm-up) {
    min-width: 100%;
  }
}

.listItem {
  min-width: 159px;
  width: 100%;
  list-style: none;
  // padding-bottom: 142%;
}

.label {
  padding: spacing(0, 2);
  text-align: right;
  margin-top: spacing(0.75);
  font-size: rem(12);

  @include devices(md-up) {
    padding: spacing(0, 7.5);
  }
}

.adsSidebar {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  padding: spacing(2) 5%;
  border: none;
  border-radius: 4px;

  .scroll {
    padding: 0;
    margin: 0;
  }

  .headline {
    padding: 0;
    line-height: 100%;
    font-size: rem(14);
    color: var(--contextual-colors-text-body);
    margin-bottom: 0;
  }

  .listItem {
    position: relative;
    max-width: 100%;
    flex: auto;
  }

  .reviveAdContainer {
    @include devices(md-up) {
      aspect-ratio: 248/353;
    }
  }

  .label {
    position: static;
    margin: 0;
    text-align: right;
    padding: 0;
  }

  @include devices(md-up) {
    padding: 16px 10%;
  }
}

.scroll {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  overscroll-behavior-x: contain;
  margin: spacing(0, 2);

  &::-webkit-scrollbar {
    display: none;
  }

  @include devices(md-up) {
    margin: spacing(0, 7.5);
  }
}

.headline {
  margin-bottom: spacing(1.25);
  font-size: rem(24);
  font-weight: var(--font-weight-bold);
  line-height: 38px;
  padding-left: spacing(2);

  @include devices(md-up) {
    padding-left: spacing(7.5);
    font-size: rem(32);
  }
}

.reviveAdContainer {
  display: block;
  aspect-ratio: 160/250;

  @include devices(md-up) {
    aspect-ratio: 192/270;
  }

  a {
    display: block;
    height: 100%;
    font-size: 0;

    img {
      min-width: 100%;
      min-height: 100%;
      height: auto;
      overflow: hidden;
      border-radius: 8px;
    }
  }
}
