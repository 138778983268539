@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.layoutContainer {
  margin-top: spacing(2);
  margin-bottom: spacing(2);
  flex-grow: 1;

  @include devices(sm-down) {
    padding: 0;
    overflow: hidden;
  }
}

.container {
  flex: 1;

  @include devices(sm-up) {
    padding-top: spacing(3);
    padding-bottom: spacing(3);
  }
}

.rightColumn {
  position: relative;
}

.trustSection {
  margin-top: calc(spacing(2) * -1);
  border-top: 1px solid var(--contextual-colors-neutral);

  @include devices(sm-up) {
    padding-left: spacing(1.5);
    padding-right: spacing(1.5);
    border-top: none;
    margin-top: 0;
  }
}

.expertAds {
  display: none !important;

  @include devices(sm-up) {
    display: flex !important;
  }
}

.sideNavigation {
  display: none;

  @include devices(sm-up) {
    display: block;
  }
}
