@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.icon {
  margin-right: 0;
  width: 9px;
}

.label {
  text-transform: capitalize;
  transition: color 0.25s;
  font-size: rem(12);
  font-weight: var(--font-weight-medium);
}
