@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.container {
  margin: spacing(0, 1);
  padding-bottom: spacing(2);

  b {
    font-weight: var(--font-weight-bold);
  }

  @include devices(md-up) {
    margin: spacing(0, 2);
  }
}
